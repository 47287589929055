

































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { rate } from "../script/RateInfo";
//import dayjs from "dayjs";
import "dayjs/locale/ja";


@Component({})
export default class About extends Vue{
  
  rates:rate[] = [];        //現在の為替相場を取得  
  rates_split:Array<Array<rate>> = [];
  rates_watch:Array<Array<rate>> = [];

  drowCount = 0;

  RateAcquiringCount　= 0;
  isRateAcquiring = false;	//取得中フラグ
  isRateAcquired = false;	//初回接続フラグ
  timerID = 0;
  buttonText = "停止";
  isInterval = true;

  testNumber = 0;
  addFlag = false;

  UpAnimationFlag:Array<Array<boolean>>=
  [
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
  ];

  DownAnimationFlag:Array<Array<boolean>>=
  [
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
	  [false,false,false],
  ];

  //---------------------ライフサイクル----------------//
  //初期化処理
  public created(){
	  this.rates_split = new Array(8);
	  this.rates_watch = new Array(8);
	  for(let i=0;i<this.rates_split.length;i++){
		  this.rates_split[i] = new Array<rate>(3);
		  this.rates_watch[i] = new Array<rate>(3);
	  }
  }

  //インスタンスが DOM 要素にマウントされた後
  public mounted(){
	this.timerID = setInterval(this.GetRateTick,1000);
  }


//    @Watch('rates_split',{deep:true})
//     public rates_splitChanged(newValue:Array<rate[]>,oldValue:Array<rate[]>){
// 		//this.rates_split[0][0].currencyPairCode
// 		//console.log(this.rates_split[0][0].currencyPairCode);
// 		//console.log(oldValue[0][0].bid + " → " + newValue[0][0].bid);
// 		// this.addFlag = true;
// 		// setTimeout(this.clearColorFlag,500);
//    }
//    @Watch("testNumber")
//    public testMethod(newValue:number,oldValue:number){
// 	   //console.log(oldValue + " → " + newValue);
//    }

//    public clearColorFlag(){
// 	   this.addFlag = false;
//    }
  //---------------------ライフサイクル----------------//

  //現在の為替相場を取得
  public async GetRate(): Promise<void>{

	  if(this.isRateAcquiring) {
          console.log(this.RateAcquiringCount + " 回目でスキップ!");
		  return;
	  }

	  this.isRateAcquiring = true;

      const result = await axios.get("https://hiro-secret.net/secretFile/gaitameonline/GetRate.php");
      this.rates = result.data.quotes;
	  let Colindex = 0;
	  let splitNum=3;
	  let tempArray = new Array<rate>(splitNum);

	  this.rates_split.splice(0);

      for(let i in this.rates){
		tempArray[Colindex] = this.rates[i];
		Colindex++;
		if(Colindex >= splitNum){
			this.rates_split.push(Object.assign({},tempArray));
			Colindex = 0;
		}
      }

	  //1回目の処理
	  if(this.RateAcquiringCount == 0){
		for(let i = 0; i < this.rates_split.length; i++){
			for(let j = 0; j < 3 ; j++){				
				this.rates_watch[i][j] = this.rates_split[i][j];
			}
		}
		this.isRateAcquired = true;
	  }
	  //2回目以降の処理
	  else{
		for(let i = 0; i < this.rates_split.length; i++){
			for(let j = 0; j < 3 ; j++){
				if(this.rates_watch[i][j].bid < this.rates_split[i][j].bid){
					//増加
					this.UpAnimationFlag[i][j] = true;
				}else if(this.rates_watch[i][j].bid > this.rates_split[i][j].bid){
					//減少
					this.DownAnimationFlag[i][j] = true;
				}
				//console.log(this.rates_watch[i][j].bid +" : "+this.rates_split[i][j].bid);				
				this.rates_watch[i][j] = this.rates_split[i][j];
			}
		}
	  }
	  this.isRateAcquiring = false;
      this.RateAcquiringCount++;
  }

  //定期的に為替相場を取得する
  public async GetRateTick() : Promise<void>{
    await this.GetRate();

	//変更された箇所を戻す(クラスバインドを外す)
	for(let i=0; i<8 ; i++){
		for(let j=0; j<3; j++){
			this.UpAnimationFlag[i][j]=false;
			this.DownAnimationFlag[i][j]=false;
		}
	}
  }

  //停止処理
  public changeAxios(){
	  this.isInterval = !this.isInterval;

	  if(!this.isInterval){
		clearInterval(this.timerID);
		this.buttonText = "開始";
	  }
	  else{
		this.timerID = setInterval(this.GetRateTick,1000);
		this.buttonText = "停止";
	  }

  }

  //https://www.gaitameonline.com/rateaj/getrate
  //https://covid19-japan-web-api.now.sh/api/v1/prefectures
  //https://hiro-secret.net/secretFile/gaitameonline/GetRate.php
}
